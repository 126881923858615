import React from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet';

import Nav, { INavProps } from 'components/common/Nav';
import Footer from 'components/common/Footer';
import SEO, { ISeoProps } from './seo';

import styles from './Layout.module.css';

interface ILayoutProps extends ISeoProps {
  layoutClassName?: string;
  navProps?: INavProps;
}

const Layout: React.FC<ILayoutProps> = ({
  children,
  layoutClassName,
  title,
  description,
  overrideTitle,
  image,
  pathname,
  article,
  audioUrl,
  additionalSeoProps,
  navProps,
}) => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Hind:300|Teko:400"
        rel="stylesheet"
      />
    </Helmet>
    <SEO
      title={title}
      description={description}
      overrideTitle={overrideTitle}
      image={image}
      pathname={pathname}
      article={article}
      audioUrl={audioUrl}
      {...additionalSeoProps}
    />
    <Nav {...navProps} />
    <div
      className={cx(styles.content, layoutClassName, {
        [styles.navHidden]: navProps?.hideUntilScroll,
      })}
    >
      {children}
    </div>
    <Footer />
  </>
);

export default Layout;
