import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import startsWith from 'lodash/startsWith';
import { GetSeoQuery } from 'types';

const getImageUrl = (defaultImage: string, image?: string) => {
  if (startsWith(image, 'http')) {
    return image;
  } else if (startsWith(image, '//')) {
    return `https:${image}`;
  } else {
    return image ? image : defaultImage;
  }
};

export interface ISeoAdditionalProps {
  name: string;
  content?: string;
}

export interface ISeoProps {
  title: string;
  description?: string;
  image?: string;
  pathname?: string;
  article?: boolean;
  overrideTitle?: boolean;
  audioUrl?: string;
  additionalSeoProps?: ISeoAdditionalProps[];
}

const SeoQuery = graphql`
  query GetSeo {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
        facebook_domain_verification_id
      }
    }
  }
`;

const SEO: React.FC<ISeoProps> = ({
  title,
  description,
  image,
  pathname,
  article = false,
  overrideTitle,
  audioUrl,
  additionalSeoProps = [],
}) => (
  <StaticQuery<GetSeoQuery>
    query={SeoQuery}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername,
          facebook_domain_verification_id,
        },
      },
    }) => {
      const imageUrl = getImageUrl(`${siteUrl}${defaultImage}`, image);

      const seo = {
        title: title || defaultTitle,
        titleTemplate: overrideTitle ? null : titleTemplate,
        description: description || defaultDescription,
        image: imageUrl,
        url: `${siteUrl}${pathname || '/'}`,
        audio: audioUrl,
      };

      return (
        <>
          <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            {/* Facebook OpenGraph */}
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.audio && <meta property="og:audio" content={seo.audio} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta
              name="facebook-domain-verification"
              content={facebook_domain_verification_id}
            />

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}

            {/* Additional Tags */}
            {additionalSeoProps.map(tag => (
              <meta name={tag.name} content={tag.content} key={name} />
            ))}
          </Helmet>
        </>
      );
    }}
  />
);

export default SEO;
