import React from 'react';
import Img from 'gatsby-image';
import BackgroundImg from 'gatsby-background-image';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { NAV_ITEMS, HOMEPAGE_ROUTE } from 'utils/routes';

import styles from './Footer.module.css';

const GET_FOOTER = graphql`
  query GetFooter {
    footerImg: file(relativePath: { eq: "footer-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    logoImg: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 191, height: 61) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;

const Footer: React.FC = () => {
  const data = useStaticQuery(GET_FOOTER);

  return (
    <footer className={styles.footer}>
      <BackgroundImg
        fluid={data.footerImg.childImageSharp.fluid}
        className={styles.footerBgImage}
      >
        <div className={styles.footerBody}>
          <Link
            to={HOMEPAGE_ROUTE}
            className={styles.footerLogoContainer}
            title="Home"
          >
            <Img
              fixed={data.logoImg.childImageSharp.fixed}
              className={styles.footerLogo}
              alt="Rock Bottom Records logo"
            />
          </Link>
          <p className={styles.footerHeader}>You reached the bottom</p>
          <nav className={styles.footerLinks}>
            {NAV_ITEMS.map(({ to, title }) => (
              <Link to={to} className={styles.footerLink} key={title}>
                {title}
              </Link>
            ))}
          </nav>
        </div>
        <div className={styles.footerOffset}>
          <span className={styles.copyright}>
            © Rock Bottom Records {new Date().getFullYear()}
          </span>
          <span className={styles.footerCredit}>
            Made by{' '}
            <a href="https://nicknish.co" className={styles.footerCreditLink}>
              Nick Nish
            </a>
          </span>
        </div>
      </BackgroundImg>
    </footer>
  );
};

export default Footer;
