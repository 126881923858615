import React, { useState, useEffect, useRef } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import cx from 'classnames';
import throttle from 'lodash/throttle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HOMEPAGE_ROUTE } from 'utils/routes';
import { isBrowser } from 'utils/environment';
import { GetNavbarQuery } from 'types';
import { useUpdateNavHeightCssVar } from './utils';

import { NavLinks } from './NavLinks';
import { NavSocialLinks } from './NavSocialLinks';
import { NavStoreCartLink } from './NavStoreCartLink';
import NavAnnouncement from './NavAnnouncement';

import Logo from 'img/logo.png';
import styles from './Nav.module.css';

const navbarQuery = graphql`
  query GetNavbar {
    site {
      siteMetadata {
        soundcloud_url
        instagram_url
        facebook_url
      }
    }

    announcements: allContentfulAnnouncementBar(
      sort: { fields: [createdAt], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          expirationDate
          announcementText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export interface INavProps {
  hideUntilScroll?: boolean;
}

let timeout: number;
let prevScrollY: number;
const SCROLL_Y_DISTANCE_TO_SEE_NAV = 15;

const Nav: React.FC<INavProps> = ({ hideUntilScroll }) => {
  const navEl = useRef<HTMLDivElement>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const { setAnnouncementOpen } = useUpdateNavHeightCssVar(navEl);

  const closeNav = () => {
    setOpened(false);
  };

  const toggleNav = () => {
    setOpened(prevOpened => !prevOpened);
  };

  const handleScroll = () => {
    if (!navEl.current) return;
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    timeout = window.requestAnimationFrame(() => {
      const scrollY = window.scrollY;
      const isScrollingUp = prevScrollY > scrollY;
      const isScrollingDown =
        scrollY - prevScrollY > SCROLL_Y_DISTANCE_TO_SEE_NAV;

      if (scrollY <= 100) {
        navEl.current.classList.remove(styles.navVisible);
        closeNav();
      } else if (isScrollingUp) {
        navEl.current.classList.add(styles.navVisible);
      } else if (isScrollingDown) {
        navEl.current.classList.remove(styles.navVisible);
        closeNav();
      }

      prevScrollY = scrollY;
    });
  };

  const throttledHandleScroll = throttle(handleScroll, 100);

  const handleClick = (e: MouseEvent) => {
    if (!navEl.current) return;
    // https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-react-component-5604830beb7f
    const clickedOffNav = !navEl.current.contains(e.target as Node);
    if (!clickedOffNav) return;
    closeNav();
  };

  useEffect(() => {
    if (isBrowser) {
      document.addEventListener('click', handleClick, false);
      if (hideUntilScroll) {
        window.addEventListener('scroll', throttledHandleScroll, false);
      }
    }

    return () => {
      if (isBrowser) {
        document.removeEventListener('click', handleClick, false);
        if (hideUntilScroll) {
          window.removeEventListener('scroll', throttledHandleScroll, false);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticQuery<GetNavbarQuery>
      query={navbarQuery}
      render={({
        site: {
          siteMetadata: { soundcloud_url, instagram_url, facebook_url },
        },
        announcements,
      }) => {
        const socialLinks = {
          soundcloud_url,
          instagram_url,
          facebook_url,
        };

        return (
          <div
            className={cx(styles.navbar, {
              [styles.hideUntilScroll]: hideUntilScroll,
            })}
            ref={navEl}
          >
            <div className={styles.navItems}>
              <div className={styles.navItemLogo}>
                <Link
                  to={HOMEPAGE_ROUTE}
                  className={styles.logoLink}
                  title="Home"
                >
                  <img
                    src={Logo}
                    className={styles.logo}
                    alt="Rock Bottom Records logo"
                  />
                </Link>
              </div>

              <div
                className={cx(
                  styles.navSocialLinks,
                  styles.mobileNavSocialLinks
                )}
              >
                <NavSocialLinks links={socialLinks} />
              </div>

              <button
                className={styles.navItemMenuIcon}
                onClick={toggleNav}
                type="button"
              >
                <FontAwesomeIcon icon="bars" size="lg" />
              </button>

              <nav className={styles.navLinks}>
                <div className={styles.navSocialLinks}>
                  <NavSocialLinks links={socialLinks} />
                </div>
                <NavLinks closeNav={closeNav} />
                <NavStoreCartLink closeNav={closeNav} />
              </nav>
            </div>

            <nav className={cx(styles.mobileNav, { db: opened })}>
              <NavLinks closeNav={closeNav} />
              <NavStoreCartLink closeNav={closeNav} />
            </nav>

            <NavAnnouncement
              announcements={announcements}
              onToggle={setAnnouncementOpen}
            />
          </div>
        );
      }}
    />
  );
};

export default Nav;
