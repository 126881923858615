import React from 'react';
import { OPEN_IN_NEW_TAB } from 'utils/urls';
import { capitalize } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import styles from '../Nav.module.css';

const renderSocialLink = ({ url, name }: { url: string; name: string }) => {
  return (
    <a
      key={url}
      href={url}
      className={styles.navSocialLink}
      title={capitalize(name)}
      {...OPEN_IN_NEW_TAB}
    >
      <FontAwesomeIcon icon={['fab', name as IconName]} size="1x" />
    </a>
  );
};

interface INavSocialLinks {
  links: {
    soundcloud_url: string;
    instagram_url: string;
    facebook_url: string;
  };
}

export const NavSocialLinks: React.FC<INavSocialLinks> = ({
  links: { soundcloud_url, facebook_url, instagram_url },
}) => {
  const NAV_SOCIAL_LINKS = [
    { url: soundcloud_url, name: 'soundcloud' },
    { url: instagram_url, name: 'instagram' },
    { url: facebook_url, name: 'facebook' },
  ];

  return <>{NAV_SOCIAL_LINKS.map(props => renderSocialLink(props))}</>;
};
