import {
  HOMEPAGE_ROUTE,
  ARTISTS_ROUTE,
  ARTIST_DETAILS_ROUTE,
  RELEASES_ROUTE,
  RELEASE_DETAILS_ROUTE,
  RADIO_ROUTE,
  RADIO_DETAILS_ROUTE,
  ABOUT_ROUTE,
  STORE_ROUTE,
  STORE_CART_ROUTE,
  STORE_ORDER_SUCCESS_ROUTE,
  buildStoreProductRouteFromHandle,
} from 'utils/routes';

import { ISeoAdditionalProps, ISeoProps } from 'components/layout/seo';

const NO_FOLLOW_TAG: ISeoAdditionalProps = {
  name: 'robots',
  content: 'noindex, nofollow',
};

export const NOT_FOUND_SEO: ISeoProps = {
  title: 'Not found',
  additionalSeoProps: [NO_FOLLOW_TAG],
};

export const LANDING_SEO: ISeoProps = {
  title: 'Rock Bottom Records',
  description:
    'Check out the latest artists and releases at Rock Bottom Records. Rock Bottom Records is an up-and-coming LA-based label started by Luxo & Posse Sauce.',
  pathname: HOMEPAGE_ROUTE,
};

export const RADIO_SEO: ISeoProps = {
  title: 'Rock Bottom Radio',
  description:
    'Check out the latest from Rock Bottom Radio, the only radio show recorded at the bottom of the ocean.',
  pathname: RADIO_ROUTE,
};

// FIXME: Fix this type
export const RELEASES_SEO: any = {
  title: 'Releases',
  description: (lastestReleaseTitles: string) =>
    `Check out the latest releases from Rock Bottom Records like ${lastestReleaseTitles}.`,
  pathname: RELEASES_ROUTE,
};

// FIXME: Fix this type
export const ARTISTS_SEO: any = {
  title: 'Artists',
  description: (latestArtistNames: string) =>
    `Explore all the Rock Bottom artists. Say hello to our new artists ${latestArtistNames}.`,
  pathname: ARTISTS_ROUTE,
};

// FIXME: Fix this type
export const ABOUT_SEO: any = {
  title: 'About',
  description: (excerpt: string) => excerpt,
  pathname: ABOUT_ROUTE,
};

// FIXME: Fix this type
export const ARTIST_DETAILS_SEO: any = {
  title: (artistName: string) => artistName,
  description: (artistName: string, excerpt: string) =>
    excerpt || `Buy and stream music from ${artistName}.`,
  image: (imageUrl: string) => imageUrl,
  pathname: (slug: string) => ARTIST_DETAILS_ROUTE(slug),
};

// FIXME: Fix this type
export const RELEASE_DETAILS_SEO: any = {
  title: (title: string) => title,
  description: (title: string, excerpt: string) =>
    `Buy and stream ${title}. ${excerpt}`,
  image: (imageUrl: string) => imageUrl,
  pathname: (slug: string) => RELEASE_DETAILS_ROUTE(slug),
  audioUrl: (audioUrl: string) => audioUrl,
};

// FIXME: Fix this type
export const RADIO_DETAILS_SEO: any = {
  title: (title: string) => title,
  description: (title: string, excerpt: string) =>
    `Stream ${title}. ${excerpt}`,
  image: (imageUrl: string) => imageUrl,
  pathname: (slug: string) => RADIO_DETAILS_ROUTE(slug),
  audioUrl: (audioUrl: string) => audioUrl,
};

export const STORE_SEO: ISeoProps = {
  title: 'Store',
  description: 'Check out the latest merch from Rock Bottom Records.',
  pathname: STORE_ROUTE,
};

// FIXME: Fix this type
export const STORE_PRODUCT_DETAILS_SEO: any = {
  title: (title: string) => title,
  description: (description: string) => description,
  image: (imageUrl: string) => imageUrl,
  pathname: (handle: string) => buildStoreProductRouteFromHandle(handle),
};

export const STORE_CART_SEO: ISeoProps = {
  title: 'Cart',
  pathname: STORE_CART_ROUTE,
  additionalSeoProps: [NO_FOLLOW_TAG],
};

export const STORE_ORDER_SUCCESS_SEO: ISeoProps = {
  title: 'Thank You',
  pathname: STORE_ORDER_SUCCESS_ROUTE,
  additionalSeoProps: [NO_FOLLOW_TAG],
};
