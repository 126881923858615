import React, { useState, useLayoutEffect, useCallback } from 'react';
import { LinkGetProps } from '@reach/router';
import isNaN from 'lodash/isNaN';
import throttle from 'lodash/throttle';

import { isBrowser } from 'utils/environment';

import styles from './Nav.module.css';

const NAV_HEIGHT_CSS_VAR_PROPERTY = '--navHeight';
type ActiveStateOutput = { className: string } | null;

export const isPartiallyActive = ({
  isCurrent,
  isPartiallyCurrent,
}: LinkGetProps): ActiveStateOutput => {
  return isCurrent || isPartiallyCurrent
    ? { className: styles.navLinkActive }
    : null;
};

export const isFullyActive = ({ isCurrent }: LinkGetProps): ActiveStateOutput =>
  isCurrent ? { className: styles.navLinkActive } : null;

const setNavHeightCssVariable = (height: string): void => {
  if (!isBrowser) {
    console.warn('Cannot set CSS variable outside browser');
    return;
  }
  const root = document.documentElement;
  root.style.setProperty(NAV_HEIGHT_CSS_VAR_PROPERTY, height);
};

// Updates nav height CSS var value based on actual height
// Solves issue of dynamic Announcement changing total height of nav
export const updateNavCssVariable = (
  navEl: React.MutableRefObject<HTMLDivElement>
) => (e?: UIEvent | null, height?: string): void => {
  if (!isBrowser) {
    return;
  }
  const navHeight = navEl?.current.offsetHeight;
  if (navHeight || height) {
    const newHeight =
      navHeight && !isNaN(navHeight) ? `${navHeight}px` : height;
    setNavHeightCssVariable(newHeight);
  }
};

/**
 * Because the Nav is fixed but has dynamic height (e.g. if there is an
 * Announcement), we need to dynamically change the corresponding page padding
 * across the app. To do this, we rely on the CSS variable navHeight and change
 * its value as the Nav height changes.
 */
export const useUpdateNavHeightCssVar = (
  navEl: React.MutableRefObject<HTMLDivElement>
) => {
  const [announcementOpened, setAnnouncementOpen] = useState<boolean>(null);
  const updateNavCssVariableHandler = useCallback(
    throttle(updateNavCssVariable(navEl), 100),
    [navEl]
  );

  // Dynamically update CSS nav height cross-app
  useLayoutEffect(() => {
    window?.addEventListener('resize', updateNavCssVariableHandler);
    return () =>
      window?.removeEventListener('resize', updateNavCssVariableHandler);
  }, [announcementOpened, updateNavCssVariableHandler]);

  // Reset nav height if announcement closed
  useLayoutEffect(() => {
    if (announcementOpened !== null) updateNavCssVariableHandler();
  }, [announcementOpened, updateNavCssVariableHandler]);

  return {
    setAnnouncementOpen,
  };
};
