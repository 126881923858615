// TODO: Add handling for multiple announcements
/**
 * This component utilizes localStorage (or other storage mechanism)
 * to handle displaying the latest unexpired announcement.
 *
 * If a user closes the displayed announcement, the announcement record id
 * will be saved to their storage and will not be shown again.
 */
import React from 'react';
import filter from 'lodash/filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GetNavbarQuery,
  ContentfulAnnouncementBar,
  MarkdownRemark,
} from 'types';

import { removedAnnouncementStoreAPI } from './utils';

import styles from '../Nav.module.css';

interface INavAnnouncementProps {
  announcements: GetNavbarQuery['announcements'];
  onToggle: (isOpen: boolean) => void;
}

interface INavAnnouncementState {
  open: boolean;
  announcement?: Pick<ContentfulAnnouncementBar, 'id' | 'expirationDate'> & {
    announcementText?: {
      childMarkdownRemark?: Pick<MarkdownRemark, 'html'>;
    };
  };
}

export default class NavAnnouncement extends React.Component<
  INavAnnouncementProps,
  INavAnnouncementState
> {
  store = removedAnnouncementStoreAPI();

  state: INavAnnouncementState = {
    open: true,
    announcement: null,
  };

  componentDidMount() {
    const { announcements } = this.props;

    // Bail out if no annoucements
    if (announcements.edges.length < 1) return;

    // Get removed announcements
    let removedAnnouncements = this.store.get();

    // Set default store
    if (!removedAnnouncements) {
      this.store.set([]);
      removedAnnouncements = [];
    }

    // Grab the latest, unexpired announcement
    const today = new Date();
    const unexpiredAnnouncements = filter(
      announcements.edges,
      ({ node: { id, expirationDate } }) => {
        const removed = removedAnnouncements.includes(id);
        const isUnexpired = new Date(expirationDate) > today;
        return !removed && isUnexpired;
      }
    );

    // Bail out if no unexpired announcements
    if (unexpiredAnnouncements.length < 1) return;

    // Set first unexpired announcement to display
    const firstUnexpiredAnnouncement = unexpiredAnnouncements[0].node;
    this.setState({ announcement: firstUnexpiredAnnouncement });
  }

  componentDidUpdate() {
    // Bubble up state to update site styling
    if (this.state.announcement && this.state.open) {
      this.props.onToggle(true);
    }
  }

  onClose = () => {
    const {
      announcement: { id },
    } = this.state;

    // Set close
    this.setState({ open: false });

    // Set id to storage
    const removedAnnouncements = this.store.get();
    this.store.set([...removedAnnouncements, id]);

    // Bubble up state to update site styling
    this.props.onToggle(false);
  };

  render() {
    const { open, announcement } = this.state;

    if (!open || !announcement) {
      return null;
    }

    return (
      <div className={styles.navAnnouncement}>
        <div
          className={styles.navAnnouncementCopy}
          dangerouslySetInnerHTML={{
            // TODO: Add target="_blank" to <a> tags in html
            __html: announcement.announcementText.childMarkdownRemark.html,
          }}
        />
        <button
          className={styles.navAnnouncementIconBtn}
          type="button"
          onClick={this.onClose}
        >
          <FontAwesomeIcon
            icon="times"
            className={styles.navAnnouncementIcon}
          />
        </button>
      </div>
    );
  }
}
