import localStore from 'store/dist/store.modern.min';
import expirePlugin from 'store/plugins/expire';

// This is not a perfect solution. The issue is the expire plugin clears the
// entire storage property, so if the user removes an announcement right before
// the expiration date is up, then they will see the announcement again.
localStore.addPlugin(expirePlugin);

const ANNOUNCEMENTS_REMOVED_STORE_KEY = 'announcements_removed';
const ANNOUNCEMENTS_REMOVED_STORE_EXPIRES = (() => {
  const today = new Date();
  const daysInFuture = 7;
  return today.setDate(today.getDate() + daysInFuture);
})();

export type RemovedAnnouncementsStore = string[];

export interface IRemoveAnnouncementsStoreApi {
  get: () => RemovedAnnouncementsStore;
  set: (val: RemovedAnnouncementsStore) => void;
}

export const removedAnnouncementStoreAPI = (): IRemoveAnnouncementsStoreApi => {
  return {
    get() {
      return localStore.get(ANNOUNCEMENTS_REMOVED_STORE_KEY);
    },
    set(val) {
      return (localStore as {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        set: (k: string, v: any, expiry: number) => any;
      }).set(
        ANNOUNCEMENTS_REMOVED_STORE_KEY,
        val,
        ANNOUNCEMENTS_REMOVED_STORE_EXPIRES
      );
    },
  };
};
