import React from 'react';
import { Link } from 'gatsby';
import { NAV_ITEMS } from 'utils/routes';
import { isPartiallyActive } from '../utils';

import styles from '../Nav.module.css';

interface INavLinks {
  closeNav: () => void;
}

export const NavLinks: React.FC<INavLinks> = ({ closeNav }) => {
  return (
    <>
      {NAV_ITEMS.map(({ to, title }) => (
        <Link
          key={title}
          to={to}
          className={styles.navLink}
          getProps={isPartiallyActive}
          onClick={closeNav}
        >
          {title}
        </Link>
      ))}
    </>
  );
};
