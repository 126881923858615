import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useStoreCartSize } from 'context/StoreContext';
import { STORE_CART_ROUTE } from 'utils/routes';
import { isPartiallyActive } from '../utils';

import styles from '../Nav.module.css';

interface INavStoreCartLink {
  closeNav: () => void;
}

interface INavStoreCartSize {
  size?: number;
}

const NavStoreCartSize: React.FC<INavStoreCartSize> = ({ size }) => {
  if (!size) return null;
  return <span className={styles.cartSize}>({size})</span>;
};

export const NavStoreCartLink: React.FC<INavStoreCartLink> = ({ closeNav }) => {
  const storeCartSize = useStoreCartSize();

  return (
    <Link
      to={STORE_CART_ROUTE}
      className={styles.navLink}
      getProps={isPartiallyActive}
      onClick={closeNav}
    >
      <FontAwesomeIcon
        icon="shopping-cart"
        size="xs"
        className={styles.cartIcon}
      />
      <span className={styles.cartText}>Cart</span>
      <NavStoreCartSize size={storeCartSize} />
    </Link>
  );
};
